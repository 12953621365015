<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Unidad - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               
                <b-col md="12">
                  <b-form-group label="Nombres:">
                    <b-form-input type="text" v-model="bus.name"></b-form-input>
                    <small v-if="errors.name"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group label="Chofer:">
                    <b-form-input type="text" v-model="bus.driver"></b-form-input>
                    <small v-if="errors.driver"  class="form-text text-danger" >Ingrese un chofer</small>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Placa:">
                    <b-form-input type="text" v-model="bus.license_plate"></b-form-input>
                    <small v-if="errors.license_plate"  class="form-text text-danger" >Ingrese una placa</small>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="N° Asientos:">
                    <b-form-select disabled v-model="bus.seating" :options="seating"></b-form-select>
                    <small v-if="errors.seating" class="form-text text-danger" >Seleccione un n° de asientos</small>
                  </b-form-group>
                </b-col>
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ['id_bus'],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Bus',
      role: 3,
      bus: {
          id_bus:'',
          name:'',
          license_plate:'',
          driver:'',
          seating:'',
          state:1,
      },
      seating:[
        {value:'',text:'-- Seleccione --'},
        {value:15,text:'15 Asientos'},
        {value:19,text:'19 Asientos'},
        {value:36,text:'36 Asientos'},
      ],

      errors: {
        name: false,
        email: false,
        country: false,
        testimony: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewBus();
  },
  methods: {
    ViewBus,
    EditBus,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ViewBus() {
  let id_bus = je.decrypt(this.id_bus);
  let me = this;
  let url = this.url_base + "bus/view/" + id_bus;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.bus.id_bus = response.data.result.id_bus;
        me.bus.name = response.data.result.name;
        me.bus.license_plate = response.data.result.license_plate;
        me.bus.driver = response.data.result.driver;
        me.bus.seating = response.data.result.seating;
        me.bus.state = response.data.result.state;
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text:"A ocurrido un error", timer: 3000,})
      me.isLoading = false;
    });
}

function EditBus() {
  let me = this;
  let url = me.url_base + "bus/edit";
  let data = me.bus;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.name = this.bus.name.length == 0 ? true : false;
  this.errors.license_plate = this.bus.license_plate.length == 0 ? true : false;
  this.errors.driver = this.bus.driver.length == 0 ? true : false;
  this.errors.seating = this.bus.seating.length == 0 ? true : false;
  
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.license_plate) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.driver) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.seating) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el bus ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditBus();
    }
  });
}
</script>
